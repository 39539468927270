import { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import ProductionOrderOverview from "./ProductionOrderOverview";
import ProductionOrderPage from "./ProductionOrderPage";
import { withAuthenticator } from "aws-amplify-react";
import { Auth } from "aws-amplify";
import {
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Toolbar,
  CircularProgress,
} from "@mui/material";
import Settings, {
  getOdooSettings,
  getStation,
  Station,
  stations,
  storeStation,
} from "./Settings";
import OdooOperationsService from "./erp/manufacturing/OdooOperationsService";
import StockPickingEditor from "./StockPickingEditor";
import StockPickingOverview from "./StockPickingOverview";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import BuildIcon from "@mui/icons-material/Build";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import LoopIcon from "@mui/icons-material/Loop";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SettingsButton from "./SettingsButton";
import { isNotBlank } from "./StringUtils";
import { Return } from "./Return";
import { Unbuild } from "./Unbuild";
import { name, OPERATION_TYPE_SERVICING, OPERATION_TYPE_SDBT, WH_STOCK } from "./erp/odoo/OdooUtils";

import { getDeviceToken, initiateCustomFlow } from "xfa-connect-sdk-js";

// https://byteflies.atlassian.net/wiki/spaces/BH/pages/32800894/House+Style
export const BYTEFLIES_BLUE_DARK = "#103a4d";
export const BYTEFLIES_BLUE = "#207499";
export const BYTEFLIES_BLUE_LIGHT = "#79acc2";
export const BYTEFLIES_RED_ACCENT = "#fc4830";

export const PENTAGRAM_WHITE = "#ffffff";
export const PENTAGRAM_LIGHT_GREY = "#f2f2f2";

const settings2 = getOdooSettings();
let production = false;
if (
  settings2?.url &&
  settings2.url !== "undefined" &&
  new URL(settings2?.url).hostname === "odoo.kitchen.byteflies.com"
) {
  production = true;
}

const defaultTheme = createTheme();

const bytefliesTheme = createTheme({
  palette: {
    primary: {
      main: BYTEFLIES_BLUE_DARK,
    },
    secondary: {
      main: BYTEFLIES_BLUE,
    },
    background: {
      paper: PENTAGRAM_WHITE,
      default: PENTAGRAM_LIGHT_GREY,
    },
  },
  typography: {
    fontFamily: `"Roboto", "Open Sans", "Helvetica", "Arial", sans-serif`,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: PENTAGRAM_WHITE,
          backgroundColor: production
            ? BYTEFLIES_BLUE_DARK
            : BYTEFLIES_RED_ACCENT,
          // only works after page refresh
        },
      },
      defaultProps: {
        color: "inherit",
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-completed": {
            color: defaultTheme.palette.success.main,
          },
        },
      },
    },
  },
});
const theme = createTheme(bytefliesTheme);

function App() {
  const [creator, setCreator] = useState("");
  const svc = new OdooOperationsService();

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsOpen(open);
    };

  useEffect(() => {
    if (creator === undefined || creator === "") {
      const settings = getOdooSettings();
      if (
        settings !== undefined &&
        settings.fullname !== undefined &&
        settings.fullname !== ""
      ) {
        setCreator(settings.fullname);
      }
    }
  }, [creator, setCreator]);

  const [station, setStation] = useState(getStation());

  //get authenticated user
  const [user, setUser] = useState<any | undefined>();
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user: any) => {
        setUser(user);
      })
      .catch((err: any) => console.log(err));
  }, []);

  //XFA Connect Integration
  //TODO mocking of external libs works in react-scripts > 5.0.1 (babel update is needed)
  if (window.localStorage.getItem("DISABLE_XFA_CONNECT") !== "1") {
    const deviceToken = getDeviceToken();

    // if no token, start XFA custom flow
    if (user && !deviceToken) {
      initiateCustomFlow(
        window.location.href,
        user.username,
        "0ea32177-4bf3-4f16-a8f9-26fb7a8a75fc"
      );

      // show a spinner
      return (
        <div className="spinnerCenter">
          <CircularProgress />
        </div>
      );
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                size="large"
              >
                <MenuIcon />
              </IconButton>

              <Typography
                variant="h6"
                key={"manufacturing"}
                sx={{ flexGrow: 1 }}
              >
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    flexGrow: 1,
                  }}
                >
                  Byteflies manufacturing
                </Link>
              </Typography>

              {isNotBlank(creator) && (
                <Typography variant="h6" key={"creator"}>
                  {creator}
                </Typography>
              )}

              <div style={{ width: "1em" }} />

              <TextField
                select
                size="small"
                variant="standard"
                value={station}
                InputProps={{
                  style: {
                    color: "white",
                  },
                }}
                onChange={(event) => {
                  const s = event.target.value as Station;
                  setStation(s);
                  storeStation(s);
                }}
              >
                {stations.map((station) => (
                  <MenuItem key={station.value} value={station.value}>
                    {station.label}
                  </MenuItem>
                ))}
              </TextField>

              <div style={{ width: "1em" }} />

              <SettingsButton />
            </Toolbar>
          </AppBar>
          <Drawer open={isOpen} onClose={toggleDrawer(false)}>
            <div
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                <ListItem
                  button
                  alignItems="flex-start"
                  component="a"
                  href="/"
                  key="production-orders"
                >
                  <ListItemIcon>
                    <BuildIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Production orders"} />
                </ListItem>

                <Divider />

                <ListItem
                  button
                  alignItems="flex-start"
                  component="a"
                  href="/unbuild"
                  key="unbuild"
                >
                  <ListItemIcon>
                    <CallSplitIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Unbuild"} />
                </ListItem>

                <ListItem
                  button
                  alignItems="flex-start"
                  component="a"
                  href="/return"
                  key="return"
                >
                  <ListItemIcon>
                    <KeyboardReturnIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Return"} />
                </ListItem>

                <ListItem
                  button
                  alignItems="flex-start"
                  component="a"
                  href="/svc"
                  key="svc"
                >
                  <ListItemIcon>
                    <LoopIcon />
                  </ListItemIcon>
                  <ListItemText primary={name(OPERATION_TYPE_SERVICING)} />
                </ListItem>

                <ListItem
                  button
                  alignItems="flex-start"
                  component="a"
                  href="/sdbt"
                  key="sdbt"
                >
                  <ListItemIcon>
                    <LoopIcon />
                  </ListItemIcon>
                  <ListItemText primary={name(OPERATION_TYPE_SDBT)} />
                </ListItem>

                <Divider />

                <ListItem
                  button
                  alignItems="flex-start"
                  component="a"
                  href="/settings"
                  key="settings"
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Settings"} />
                </ListItem>

                <Divider />

                <ListItem className="boldText">Keyboard Shortcuts:</ListItem>
                <ListItem>D - Mark As Done</ListItem>
                <ListItem>V - Validate</ListItem>
                <ListItem>B - BOM page</ListItem>
                <ListItem>P - Pass QC</ListItem>
                <ListItem>F - Fail QC</ListItem>
                <ListItem>h / l - foward/back</ListItem>
                <ListItem>[ / ] - first/last QC</ListItem>
              </List>
            </div>
          </Drawer>
          <Switch>
            <Route exact path="/">
              <ProductionOrderOverview svc={svc} />
            </Route>
            <Route exact path="/settings">
              <Settings />
            </Route>
            <Route path="/production-order/:id">
              <ProductionOrderPage svc={svc} basePath="/production-order" />
            </Route>
            <Route exact path="/svc">
              <StockPickingOverview svc={svc} basePath="/svc" operationType={OPERATION_TYPE_SERVICING}/>
            </Route>
            <Route path="/svc/:spid/:mlid/:qcIdx?">
              <StockPickingEditor svc={svc} basePath="/svc" defaultLocationDestId={WH_STOCK} />
            </Route>
            <Route exact path="/sdbt">
              <StockPickingOverview svc={svc} basePath="/sdbt" operationType={OPERATION_TYPE_SDBT}/>
            </Route>
            <Route path="/sdbt/:spid/:mlid/:qcIdx?">
              <StockPickingEditor svc={svc} basePath="/sdbt" defaultLocationDestId={WH_STOCK} />
            </Route>
            <Route exact path="/return">
              <Return svc={svc} />
            </Route>
            <Route exact path="/unbuild">
              <Unbuild svc={svc} />
            </Route>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default withAuthenticator(App);
